<template>
  <div class="vv-drawer__content">
    <el-form
      :model="wxUser"
      status-icon
      ref="wxUserStatus"
      label-width="80px"
    >
      <el-form-item label="状态">
        <el-select
          v-model="wxUser.status"
          style="width: 100%"
          placeholder="请选择状态"
        >
          <el-option
            v-for="item in statusOpt"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="vv-text_r">
      <el-button :loading="loading" type="primary" @click="onSubmit"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import { statusWxUser } from "@/api/wx-user";
import {
  Form,
  FormItem,
  Select,
  Option,
  Button,
  Message,
} from "element-ui";
export default {
  name: "wxUserStatus",
  props: {
    type: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: 0,
    }
  },
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },
  data() {
    return {
      wxUser: {
        status: "deactive",
      },
      statusOpt: [{
        label: "启用",
        value: "active"
      }, {
        label: "禁用",
        value: "deactive"
      }],
      loading: false,
    };
  },
  methods: {
    clearFun() {
      this.$refs["wxUserStatus"].clearValidate();
      this.wxUser = {
        status: "deactive"
      }
    },
    onSubmit() {
      this.loading = true;
      statusWxUser(this.id, this.wxUser)
        .then(() => {
          Message({
            type: "success",
            message: "档案状态修改成功",
          });
          this.$emit("close", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          if (this.status === 3) {
            this.wxUser.status = "deactive"
          } else {
            this.wxUser.status = "active"
          }
        }
      },
      immediate: true
    }
  }
};
</script>
