import axios from "@/utils/request";

export function getWxUser(data) {
  return axios({
    url: "/wxusers",
    method: "get",
    params: data,
  });
}

export function editWxUser(id, data) {
  return axios({
    url: `/wxusers/${id}`,
    method: "put",
    data,
  });
}

export function readWxUser(id) {
  return axios({
    url: `/wxusers/${id}`,
    method: "get",
  });
}

export function addWxUser(data) {
  return axios({
    url: "/wxusers",
    method: "post",
    data,
  });
}

export function delWxUser(id) {
  return axios({
    url: `/wxusers/${id}`,
    method: "delete",
  });
}

export function statusWxUser(id, data) {
  return axios({
    url: `/wxusers/${id}/status`,
    method: "post",
    data,
  });
}

export function unbindWxUser(id, data) {
  return axios({
    url: `/wxusers/${id}/unbind`,
    method: "post",
    data,
  });
}

export function clearalldata(data) {
  return axios({
    url: `/clearalldata`,
    method: "post",
    data,
  });
}