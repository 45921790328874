<template>
  <div>
    <el-card v-loading="loading" shadow="hover">
      <div slot="header">
        <el-row :gutter="20">
          <el-col :span="3">
            <el-input
              v-model="name"
              placeholder="请输入姓名"
              @input="onName"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="school"
              placeholder="请输入校区"
              @input="onSchool"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="classes"
              placeholder="请输入班级"
              @input="onClass"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="grade"
              placeholder="请输入年级"
              @input="onGrade"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="identity"
              placeholder="请输入身份证"
              @input="onIdentity"
            ></el-input>
          </el-col>
          <el-col :span="9" class="vv-text_r">
            <el-button
              size="small"
              type="danger"
              icon="el-icon-delete"
              @click="onAllDel"
            >
              清空数据
            </el-button>

            <el-button
              size="small"
              type="primary"
              icon="el-icon-download"
              @click="onDown"
              >下载模板</el-button
            >

            <el-upload
              :action="actionUrl"
              :headers="headers"
              accept=".xlsx"
              :multiple="false"
              :show-file-list="false"
              style="display: inline-block;margin: 0 10px;"
              :on-success="onSuccess"
              :on-error="onError">
              <el-button size="small" icon="el-icon-upload2" type="primary">批量导入</el-button>
            </el-upload>

            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="onAdd"
              >新建档案</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="name" label="姓名" width="100">
        </el-table-column>
        <el-table-column prop="identity" label="身份证" width="200">
        </el-table-column>
        <el-table-column prop="school" label="校区" width="100">
        </el-table-column>
        <el-table-column prop="grade" label="年级" width="120">
        </el-table-column>
        <el-table-column prop="class" label="班级">
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100">
          <template slot-scope="scope">
            <el-tag
              size="mini"
              effect="dark"
              :type="statusType[scope.row.status - 1]"
            >
              {{ statusVal[scope.row.status - 1] }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="350"
          header-align="left"
          align="right"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="onEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-popconfirm :title="`确定要解绑${scope.row.name}吗？`" @confirm="onUnbind(scope.row.id)" style="margin: 0 10px">
              <el-button
                slot="reference"
                size="mini"
                type="success"
                icon="el-icon-s-goods"
                :disabled="scope.row.status === 1"
              >解绑</el-button>
            </el-popconfirm>
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-s-tools"
              @click="onStatus(scope.row)"
              :disabled="scope.row.status === 1"
              >状态</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="onDel(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="onCurrentChange"
        hide-on-single-page
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-drawer
      :title="title"
      size="25%"
      :visible.sync="drawer"
      :wrapperClosable="false"
      :before-close="onClose"
    >
      <wx-user-form
        ref="wxUserForm"
        :id="id"
        :show="drawer"
        :type="type"
        @close="formClose"
        v-if="type !== 'status'"
      />
      <wx-user-status
        ref="wxUserStatus"
        :id="id"
        :show="drawer"
        :type="type"
        :status="status"
        @close="formClose"
        v-if="type === 'status'"
      />
    </el-drawer>
  </div>
</template>

<script>
import { getWxUser, delWxUser, unbindWxUser, clearalldata } from "@/api/wx-user";
import { getToken } from "@/utils/auth";
import {
  Card,
  Row,
  Col,
  Input,
  Select,
  Option,
  Upload,
  Button,
  Table,
  TableColumn,
  Pagination,
  Tag,
  Drawer,
  Popover,
  Popconfirm,
  MessageBox,
  Message,
} from "element-ui";
import WxUserForm from "./form";
import WxUserStatus from "./form/status";
export default {
  name: "wxUser",
  components: {
    [Card.name]: Card,
    [Row.name]: Row,
    [Col.name]: Col,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Upload.name]: Upload,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Tag.name]: Tag,
    [Drawer.name]: Drawer,
    [Popover.name]: Popover,
    [Popconfirm.name]: Popconfirm,
    WxUserForm,
    WxUserStatus
  },
  data() {
    return {
      loading: false,
      statusType: ['info','success','danger'],
      statusVal: ['未绑定','已绑定','禁用'],
      status: null,
      name: null,
      school: null,
      classes: null,
      grade: null,
      identity: null,
      actionUrl: `${global.config.url}/wxusers/batch`,
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      tableData: [],
      pagesize: 10,
      total: 0,
      currentPage: 1,
      title: null,
      drawer: false,
      type: null,
      id: 0,
    };
  },
  methods: {
    onName() {
      this.fetchData();
    },
    onSchool() {
      this.fetchData();
    },
    onClass() {
      this.fetchData();
    },
    onGrade() {
      this.fetchData();
    },
    onIdentity() {
      this.fetchData();
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.fetchData();
    },
    onCurrentChange(val) {
      this.currentPage = val;
      this.fetchData();
    },
    onDown() {
      let a = document.createElement('a')
      a.href = "/template/定制公交车乘车人导入模板.xlsx";
      a.click();
    },
    onAllDel() {
      MessageBox.confirm("确定清空所有数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        clearalldata().then(() => {
          Message.success("清空数据成功");
          this.fetchData();
        });
      });
    },
    onUnbind(id) {
      this.loading = true;
      unbindWxUser(id)
        .then(() => {
          Message.success("解绑成功");
          this.fetchData();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSuccess() {
      Message.success("批量导入成功");
      this.fetchData();
    },
    onError(err) {
      Message({
        type: "error",
        duration: 5000,
        message: JSON.parse(err.message).message,
      });
    },
    onStatus(item) {
      this.id = item.id;
      this.drawer = true;
      this.title = `${item.name} 状态`;
      this.status = item.status;
      this.type = "status";
    },
    onAdd() {
      this.drawer = true;
      this.title = "新建档案";
      this.type = "add";
    },
    onEdit(id) {
      this.id = id;
      this.drawer = true;
      this.title = "编辑档案";
      this.type = "edit";
    },
    onDel(id) {
      MessageBox.confirm("确定删除该档案吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delWxUser(id).then(() => {
          Message.success("删除成功");
          this.fetchData();
        });
      });
    },
    fetchData() {
      this.loading = true;
      getWxUser({
        page_id: this.currentPage,
        page_size: this.pagesize,
        name: this.name,
        school: this.school,
        class: this.classes,
        grade: this.grade,
        identity: this.identity
      })
        .then((rs) => {
          this.tableData = rs.data.data;
          this.total = rs.data.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClose(done) {
      if (this.type === 'status') {
        this.$refs["wxUserStatus"].clearFun();
      } else {
        this.$refs["wxUserForm"].clearFun();
      }
      done();
    },
    formClose(status) {
      if (this.type === 'status') {
        this.$refs["wxUserStatus"].clearFun();
      } else {
        this.$refs["wxUserForm"].clearFun();
      }
      this.drawer = status;
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
