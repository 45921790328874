<template>
  <div class="vv-drawer__content">
    <el-form
      :model="wxUser"
      status-icon
      :rules="wxUserRules"
      ref="wxUserForm"
      label-width="80px"
    >
      <el-form-item label="姓名" prop="name">
        <el-input type="text" v-model="wxUser.name"></el-input>
      </el-form-item>
      <el-form-item label="身份证" prop="identity">
        <el-input type="text" v-model="wxUser.identity"></el-input>
      </el-form-item>
      <el-form-item label="校区">
        <el-input type="text" v-model="wxUser.school"></el-input>
      </el-form-item>
      <el-form-item label="年级">
        <el-input type="text" v-model="wxUser.class"></el-input>
      </el-form-item>
      <el-form-item label="班级">
        <el-input type="text" v-model="wxUser.grade"></el-input>
      </el-form-item>
    </el-form>
    <div class="vv-text_r">
      <el-button :loading="loading" type="primary" @click="onSubmit"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import { addWxUser, readWxUser, editWxUser } from "@/api/wx-user";
import {
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Button,
  Message,
} from "element-ui";
export default {
  name: "wxUserForm",
  props: {
    type: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },
  data() {
    return {
      wxUser: {
        name: null,
        identity: null,
        school: null,
        class: null,
        grade: null
      },
      wxUserRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        identity: [
          { required: true, message: "请输入身份证", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  methods: {
    onRead() {
      readWxUser(this.id).then((rs) => {
        this.wxUser = rs.data.data;
      });
    },
    clearFun() {
      this.$refs["wxUserForm"].clearValidate();
      this.wxUser = {
        name: null,
        identity: null,
        school: null,
        class: null,
        grade: null
      };
    },
    onSubmit() {
      this.$refs["wxUserForm"]["validate"]((valid) => {
        if (valid) {
          this.loading = true;
          switch (this.type) {
            case "add":
              addWxUser(this.wxUser)
                .then(() => {
                  Message({
                    type: "success",
                    message: "档案新建成功",
                  });
                  this.$emit("close", false);
                })
                .finally(() => {
                  this.loading = false;
                });
              break;
            case "edit":
              editWxUser(this.id, this.wxUser)
                .then(() => {
                  Message({
                    type: "success",
                    message: "档案修改成功",
                  });
                  this.$emit("close", false);
                })
                .finally(() => {
                  this.loading = false;
                });
              break;
          }
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          if (this.type === "edit") {
            this.onRead();
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
